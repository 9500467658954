@tailwind base;
@tailwind components;
@tailwind utilities;

/* Amplitude Font */
/* Light */
@font-face {
  font-family: 'Amplitude';
  src: url('./assets/fonts/Amplitude/amplitude-light.woff2') format('woff2'),
    url('./assets/fonts/Amplitude/amplitude-light.woff') format('woff');
  font-weight: 300;
}
/* Regular */
@font-face {
  font-family: 'Amplitude';
  src: url('./assets/fonts/Amplitude/amplitude-regular.woff') format('woff');
  font-weight: 400;
}
/* Medium */
@font-face {
  font-family: 'Amplitude';
  src: url('./assets/fonts/Amplitude/amplitude-medium.woff') format('woff');
  font-weight: 500;
}
/* Bold */
@font-face {
  font-family: 'Amplitude';
  src: url('./assets/fonts/Amplitude/46501afd-1244-4d1c-8f6b-982a16dbd086-5.woff2')
      format('woff2'),
    url('./assets/fonts/Amplitude/46501afd-1244-4d1c-8f6b-982a16dbd086-3.woff')
      format('woff');
  font-weight: 700;
}

@font-face {
  font-family: 'Celeste';
  src: url('./assets/fonts/Celeste/CelesteOT.woff2') format('woff2');
  font-weight: 400;
}

body {
  margin: 0;
  font-family: 'Amplitude', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #31373d;
}

html,
body,
#root {
  height: 100%;
}
body::-webkit-scrollbar {
  width: 1rem;
}

body::-webkit-scrollbar-track {
  background-color: #e2e4e5;
}

body::-webkit-scrollbar-thumb {
  background-color: #2b9ab3;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a[target='_blank']::after {
  content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAQElEQVR42qXKwQkAIAxDUUdxtO6/RBQkQZvSi8I/pL4BoGw/XPkh4XigPmsUgh0626AjRsgxHTkUThsG2T/sIlzdTsp52kSS1wAAAABJRU5ErkJggg==);
  margin: 0 3px 0 5px;
}
